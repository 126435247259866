@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.govgr-card--border.govgr-card--border-gray {
  border-width: 2px;
  border-radius: 5px;
}

.custom-tooltip {
  border-radius: 0.25rem;
  background: #ffffff;
  color: primary;
  padding: 1rem;
  box-shadow: 15px 30px 40px 5px rgba(167, 167, 167, 0.5);
}

.react-datepicker__day--in-selecting-range {
  background-color: white !important;
  color: #000 !important;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end {
  background-color: #216ba5 !important;

  color: #fff !important;
}
